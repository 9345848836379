import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Parenthood and programming, powered by caffeine.`}</p>
    </blockquote>
    <p>{`I am an avid programmer, working full time as a Software Applications Developer and spending as much of my free time as I can learning cool new stuff. I'm also passionate about music; you can find me jamming with the Worship Team at `}<a parentName="p" {...{
        "href": "https://tfmchurch.com"
      }}>{`TFMC Church`}</a>{` every Sunday. Above all, I am a husband and father of two beautiful daughters striving to put Christ in the center of it all.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      