import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="cvfree church" link="https://cvfree.church" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  I built an awesome website for my home church using WordPress. Check it out!
    </ProjectCard>
    <ProjectCard title="Here's Jesus" link="https://www.heresjesus.org" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  This is a React web app developed and launched alongside Bishop David Roller's published book Here's Jesus, offering an interactive interface to track progress in watching the associated videos.
    </ProjectCard>
    <ProjectCard title="Give Have Need" link="https://www.givehaveneed.com" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  A non-profit initiative I helped develop to offer small businesses a gateway into eCommerce by allowing them to sell digital giftcards during the COVID-19 pandemic.
    </ProjectCard>
    <ProjectCard title="Serenity Hobbies Norwich" link="https://www.serenitynorwich.com" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  A website and eCommerce solution built for my favorite local gaming store in Norwich, NY. Check it out, and buy something while you're there!
    </ProjectCard>
    <ProjectCard title="Improve Norwich Now" link="https://www.improvenorwichnow.org" bg="linear-gradient(to right, #4568DC 0%, #B06AB3 100%)" mdxType="ProjectCard">
  A WordPress website developed for a local community action nonprofit organization that I volunteer with. Check it out and learn more about how we're working to Improve Norwich Now!
    </ProjectCard>
    <ProjectCard title="Genesis Conference" link="https://www.genesisfmc.com" bg="linear-gradient(to right, #56ab2f 0%, #a8e063 100%)" mdxType="ProjectCard">
  A WordPress website developed for a local community action nonprofit organization that I volunteer with. Check it out and learn more about how we're working to Improve Norwich Now!
    </ProjectCard>
    <h2>{`Want to see your project here? `}<a parentName="h2" {...{
        "href": "/#contact"
      }}>{`Get in touch with me`}</a></h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      